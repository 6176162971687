import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "primereact/button";
import CreateUpdateEvent from "./CreateUpdateEvent";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteEvents,
    createEvent,
    loadEvents,
    updateEvent,
    updateEventStatus,
    updateEventsStatus
} from "../../store/events/events.slice";
import {loadTanzeemLevels, loadTanzeems} from "../../store/tanzeem/tanzeem.slice";
import FullOverlaySpinner from "../../components/LoadingSpinner/FullOverlaySpinner";
import {defaultErrorMessageToast, getToast} from "../../utils/toastHelper";
import {EventDto} from "nap-events-client";
import {capitalizeString, prettifyLabel} from "../../utils/utils";
import {Tag} from "primereact/tag";
import ApproveEvent from "./ApproveEvent";
import {Divider} from "primereact/divider";
import PrintPanel from "./PrintPanel";
import ReactToPrint from "react-to-print";
import CancelRejectEvent from "./CancelRejectEvent";
import {isAdmin, isManager, isUsersAimsId} from "../../utils/userHelper";
import {keycloak} from "../../app_layout/Keycloak";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {Message} from "primereact/message";

const EVENTS_PAGING_SIZE = 10;
export default function Events() {
    const dispatch =  useDispatch()
    const {tanzeems ,tanzeemLevels, tanzeemsLoading, tanzeemLevelsLoading} = useSelector(state => state.tanzeem)
    const {events, eventsLoading, createEventLoading, updateEventLoading, updateEventStatusLoading, deleteEventLoading} = useSelector(state => state.events);
    const [tableRows, setTableRows] = useState(EVENTS_PAGING_SIZE)
    const [firstRowIndex, setFirstRowIndex] = useState(0)
    
    const [eventDialog, setEventDialog] = useState(false);
    const [approveEventDialog, setApproveEventDialog] = useState(false);
    const [viewEventDialog, setViewEventDialog] = useState(false);
    const [rejectEventDialog, setRejectEventDialog] = useState(false);
    const [cancelEventDialog, setCancelEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [deleteEventsDialog, setDeleteEventsDialog] = useState(false);
    const [event, setEvent] = useState(null);
    const [selectedEvents, setSelectedEvents] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = getToast();
    const toastErrorMessage = (response) => {defaultErrorMessageToast(toast,response)};
    const dt = useRef(null);
    
    const isTableLoading = () => {
        return tanzeemsLoading || tanzeemLevelsLoading || eventsLoading || updateEventStatusLoading
    } 
    
    useEffect(() =>{
        if(!tanzeems || (tanzeems.length && tanzeems.length === 0))
        {
            dispatch(loadTanzeems( {finalData: {},callbackError: toastErrorMessage}))
        }

        if(!tanzeemLevels || (tanzeemLevels.length && tanzeemLevels.length === 0))
        {
            dispatch(loadTanzeemLevels( {finalData: {},callbackError: toastErrorMessage}))
        }
        loadItems();
    },[])
    
    const loadItems = (options: DataTableStateEvent) => {
        if(options)
        {
            setTableRows(options.rows)
            setFirstRowIndex(options.first)
            //dispatch(loadEvents( {finalData: {page: options.page, size: options.rows}, callbackError: toastErrorMessage}))
            dispatch(loadEvents( {finalData: {page: 0, size: -1}, callbackError: toastErrorMessage}))
        }
        else{
            setTableRows(EVENTS_PAGING_SIZE)
            setFirstRowIndex(0)
            //dispatch(loadEvents( {finalData: {page: 0, size: EVENTS_PAGING_SIZE}, callbackError: toastErrorMessage}))
            dispatch(loadEvents( {finalData: {page: 0, size: -1}, callbackError: toastErrorMessage}))
        }
    }

    const openNew = () => {
        setEvent(null);
        setEventDialog(true);
    };

    const hideDialog = () => {
        setEventDialog(false);
        setEvent(null);
    };

    const hideApproveDialog = () => {
        setApproveEventDialog(false);
        setEvent(null);
    };

    const hideViewDialog = () => {
        setViewEventDialog(false);
        setEvent(null);
        setSelectedEvents(null);
    };

    const hideRejectDialog = () => {
        setRejectEventDialog(false);
        setEvent(null);
    };

    const hideCancelDialog = () => {
        setCancelEventDialog(false);
        setEvent(null);
    };
    
    const hideDeleteEventDialog = () => {
        setDeleteEventDialog(false);
        setEvent(null);
    };

    const hideDeleteEventsDialog = () => {
        setDeleteEventsDialog(false);
        setEvent(null);
    };

    const saveEvent = (data) => {
        
        console.log("save")
        console.log(data);
        console.log(event);
        if (data) {
            const editedEvent : EventDto = {
                ...event,
                id: event ? event.id : null,
                eventType: data.eventType,
                tanzeemUnit: data.tanzeemUnit,
                location: data.location,
                startDateTime: data.startDateTime,
                endDateTime: data.endDateTime,
                agenda: data.agenda,
                comments: event ? event.comments : null,
                status: event ? event.status : "ADDED",
                customTitle: data.customTitle,
                expenseDetails: {
                    hospitality: data.hospitality,
                    rent: data.rent,
                    others: data.others,
                    numberOfMenus: data.numberOfMenus,
                    menus: data.menus,
                    estimateParticipants: data.estimateParticipants,
                    selfInvolvement: data.selfInvolvement,
                    approvedAmount: event ? event.expenseDetails.approvedAmount : null,
                    expensesApproved: event ? event.expenseDetails.expensesApproved : false,
                    budgetComment: data.budgetComment
                }
                
            }
            if(event && event.id)
            {
                updateSelectedEvent(editedEvent)    
            }
            else{
                dispatch(createEvent({finalData: editedEvent, callbackSuccess: eventCreated, callbackError: toastErrorMessage}))
            }
            
        }
    };
    
    const updateSelectedEvent  = (event) => {
        dispatch(updateEvent({finalData: event, callbackSuccess: eventUpdated, callbackError: toastErrorMessage}))
    }

    const updateStatusOfEvent  = (event, status) => {
        dispatch(updateEventStatus({finalData: {eventId: event.id, status: status}, callbackSuccess: eventUpdated, callbackError: toastErrorMessage}))
    }

    const updateStatusOfEvents  = (eventIds, status) => {
        dispatch(updateEventsStatus({finalData: {ids: eventIds, status: status}, callbackSuccess: eventsUpdated, callbackError: toastErrorMessage}))
    }

    const eventCreated = (response) => {
        loadItems();
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: "Event: " +response.eventType.label + " created!",
            life: 3000
        });
        setEventDialog(false);
    };

    const eventUpdated = (response) => {
        loadItems();
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: "Event: " +response.eventType.label + " updated!",
            life: 3000
        });
        setEventDialog(false);
    };

    const eventsUpdated = (response) => {
        loadItems();
        toast.current.show({
            severity: 'success',
            summary: 'Successful',
            detail: response.length + "x Events updated!",
            life: 3000
        });
        setEventDialog(false);
    };
    
    const editEvent = (event) => {
        setEvent({...event});
        setEventDialog(true);
    };

    const submitAccept = (event) => {
        submitEventAfterCheck(event)
    }

    const submitRejected = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Submit rejected', life: 3000 });
    }
    
    const submitEvent = (event) => {
        if(isEventApprovalRequired(event))
        {
            confirmDialog({
                message: 'Wurde das Programm mit der Region besprochen?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                defaultFocus: 'accept',
                accept: () => {submitAccept(event)},
                reject: submitRejected,
                acceptLabel: "Ja",
                rejectLabel: "Nein"
            });
        }else{
            submitEventAfterCheck(event) 
        }
    };

    const submitEventAfterCheck = (event) => {
        updateStatusOfEvent(event, "SUBMITTED")
    };

    const reviewEvent = (event) => {
        updateStatusOfEvent(event, "IN_PROGRESS")
        setEvent(event)
        setViewEventDialog(true)
    };

    const reviewEvents = () => {
        updateStatusOfEvents(selectedEvents.map(selectedEvent => selectedEvent.id), "IN_PROGRESS")
        setViewEventDialog(true)
    };

    const rejectEvent = (event) => {
        setEvent(event)
        setRejectEventDialog(true)
    };

    const approveEvent = (event) => {
        setEvent(event)
        setApproveEventDialog(true)
    };

    const viewEvent = (event) => {
        setEvent(event)
        setViewEventDialog(true)
    };

    const cancelEvent = (event) => {
        setEvent(event)
        setCancelEventDialog(true)
    };
    
    const confirmDeleteEvent = (event) => {
        setEvent(event);
        setDeleteEventDialog(true);
    };

    const deleteEvent = () => {
        dispatch(deleteEvents({finalData: [event.id], callbackSuccess: () => {
                setDeleteEventDialog(false);
                setEvent(null);
                loadItems();
                toast.current.show({severity: 'success', summary: 'Successful', detail: 'Event Deleted', life: 3000});
            }, callbackError: toastErrorMessage }));
    };
    
    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteEventsDialog(true);
    };

    const deleteSelectedEvents = () => {
        dispatch(deleteEvents({finalData: selectedEvents.map(selectedEvent => selectedEvent.id), callbackSuccess: () => {
                setDeleteEventsDialog(false);
                setSelectedEvents(null);
                loadItems();
                toast.current.show({severity: 'success', summary: 'Successful', detail: 'Events Deleted', life: 3000});
            }, callbackError: toastErrorMessage }));
    };
    
    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Neu" icon="pi pi-plus" severity="primary" onClick={openNew}/>
                <Button label="Löschen" icon="pi pi-trash" severity="danger" onClick={confirmDeleteSelected}
                        disabled={!selectedEvents || !selectedEvents.length || selectedEvents.filter(sEvent => sEvent.status !== "ADDED").length > 0}/>
                <Button label="Freigeben" icon="pi pi-trash" severity="success" onClick={() => {approveEvent(null)}}
                        disabled={!selectedEvents || !selectedEvents.length || selectedEvents.filter(sEvent => sEvent.status !== "IN_PROGRESS").length > 0}/>
                {isManager(keycloak) ? <Button label="In Progress" icon="pi pi-lock" severity="warning" onClick={() => {reviewEvents()}}
                        disabled={!selectedEvents || !selectedEvents.length || selectedEvents.filter(sEvent => sEvent.status !== "APPROVAL_REQUESTED").length > 0}/> : ""}
            </div>
        );
    };

    const rightToolbarTemplate = () => {
        return <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV}/>;
    };

    const statusBody = (rowData, options) => {
        let tag;
        switch (rowData[options.field]) {
            case "ADDED":
                tag = <><Tag style={{background: "#64748b"}} severity="secondary" value="ADDED"/><Message severity="info" text="Owner needs to submit Request for further process" /></>
                break;
            case "APPROVAL_REQUESTED":
                tag = <Tag severity="warning" value="APPROVAL_REQUESTED"/>
                break;
            case "IN_PROGRESS":
                tag = <Tag severity="warning" value="IN_PROGRESS"/>
                break;
            case "EVENT_APPROVED":
                tag = <Tag severity="success" value="EVENT_APPROVED"/>
                break;
            case "EVENT_CANCELED":
                tag = <Tag severity="danger" value="CANCELED"/>
                break;
            default:
                tag = <Tag severity="info" value={rowData[options.field]}/>

        }
        return <div className="flex flex-column justify-content-center">{tag}</div>;
    };
    
    const isEventApprovalRequired = (event: EventDto) => {
            return event.eventType.tanzeemLevel === "majlis" && (event.eventType.approvalRequired || event.eventType.budgetRequired === "required" || (event.eventType.budgetRequired === "optional"
                    && (event.expenseDetails.hospitality > 0 || event.expenseDetails.rent > 0 || event.expenseDetails.others > 0)));
    }
    
    const capitalizedBody = (rowData, options) => {
        const result = get(rowData, options.field.split("."))
        return prettifyLabel(result[0]);
    };

    const get = (from, ...selectors) => {
        return [...selectors].map(s =>
            s
                .filter(t => t !== '')
                .reduce((prev, cur) => prev && prev[cur], from)
        );
    }
    
    const booleanBodyTemplate = (rowData, options) => {
        return (
            <React.Fragment>
                {rowData[options.field] === true ? "Ja" : "Nein"}
            </React.Fragment>
        );
    };

    const totalCosts = (rowData) => {
        return rowData.expenseDetails.hospitality +rowData.expenseDetails.others +rowData.expenseDetails.rent;
    };
    const dateTemplate = (rowData) => {
        return (
            <React.Fragment>
                {moment.utc(rowData.startDateTime).local().format("DD.MM.YYYY HH:mm")}
            </React.Fragment>
        );
    };

    const viewItemTemplate = (rowData) => {
        return (
            <div className="flex flex-column">
                <Button icon="pi pi-eye" rounded severity="info" className="mr-2" onClick={() => viewEvent(rowData)} label="View"/>
            </div>
        );
    };
    
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex flex-column">
                <Button size="small" raised icon="pi pi-eye" rounded severity="info" className="mr-2" onClick={() => viewEvent(rowData)} label="View"/>
                {isUsersAimsId(keycloak, rowData.requesterAimsId) && (rowData.status === "ADDED" || rowData.status === "REJECTED") ? <Button icon="pi pi-arrow-right" size="small" raised rounded severity="success" className="mr-2" label="Submit" onClick={() => submitEvent(rowData)}/> : ""}
                {isManager(keycloak) && (rowData.status === "APPROVAL_REQUESTED") ? <Button icon="pi pi-lock" rounded severity="warning" className="mr-2" size="small" raised onClick={() => reviewEvent(rowData)} label="InProgress" /> : ""}
                {isManager(keycloak) && (rowData.status === "IN_PROGRESS") ? <Button icon="pi pi-check" rounded severity="success" className="mr-2" size="small" raised label="Approve" onClick={() => approveEvent(rowData)}/> : ""}
                {isManager(keycloak) && (rowData.status === "IN_PROGRESS") ? <Button icon="pi pi-times" rounded severity="danger" className="mr-2" size="small" raised label="Reject" onClick={() => rejectEvent(rowData)}/> : ""}
                {isUsersAimsId(keycloak, rowData.requesterAimsId) && (rowData.status === "ADDED" || rowData.status === "REJECTED" || rowData.status === "EVENT_APPROVED") ? <Button icon="pi pi-pencil" size="small" raised rounded label="Edit" className="mr-2" onClick={() => editEvent(rowData)}/> : "" }
                {(isUsersAimsId(keycloak, rowData.requesterAimsId) || isAdmin(keycloak)) && (rowData.status === "ADDED") ? <Button icon="pi pi-trash" className="mr-2" rounded size="small" raised severity="danger" label="Delete" onClick={() => confirmDeleteEvent(rowData)}/> : "" }
                {(isUsersAimsId(keycloak, rowData.requesterAimsId) || isAdmin(keycloak)) && (rowData.status === "EVENT_APPROVED" || rowData.status === "REJECTED") ? <Button icon="pi pi-ban" className="mr-2" size="small" raised rounded severity="danger" label="Cancel" onClick={() => cancelEvent(rowData)}/> : "" }
                {isAdmin(keycloak) && (rowData.status === "EVENT_CANCELED") ? <Button icon="pi pi-trash" rounded size="small" className="mr-2" raised severity="danger" onClick={() => confirmDeleteEvent(rowData)}/> : "" }
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Manage Events</h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search"/>
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..."/>
            </span>
        </div>
    );
    const deleteEventDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteEventDialog}/>
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteEvent}/>
        </React.Fragment>
    );
    const deleteEventsDialogFooter = (
        <React.Fragment>
            <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteEventsDialog}/>
            <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedEvents}/>
        </React.Fragment>
    );
    
    return (
        <div className="content">
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                <DataTable loadingIcon={<i className="pi pi-spin pi-sync" style={{ color: "green", fontSize: '3rem' }}></i>}
                           loading={isTableLoading()} ref={dt} 
                           value={events?.content} 
                           //lazy
                           first={firstRowIndex}
                           //onPage={loadItems}
                           totalRecords={events?.totalElements}
                           selection={selectedEvents}
                           onSelectionChange={(e) => setSelectedEvents(e.value)}
                           dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                           currentPageReportTemplate="{first} bis {last} von {totalRecords} Einträgen werden angezeigt"
                           //globalFilter={globalFilter} 
                           //header={header}
                           sortMode="multiple"
                           removableSort={true}
                           filterDisplay="row">
                    <Column selectionMode="multiple" exportable={false}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}></Column>
                    <Column field="eventType.label" header="Name" sortable style={{minWidth: '8rem'}}></Column>
                    <Column field="eventType.tanzeem" filter filterMatchMode="contains" header="Tanzeem" sortable style={{minWidth: '8rem'}} body={capitalizedBody}></Column>
                    <Column field="eventType.tanzeemLevel" filter filterMatchMode="contains" header="Level" sortable style={{minWidth: '8rem'}} body={capitalizedBody}></Column>
                    <Column field="tanzeemUnit" filter filterMatchMode="contains" header="Region/Majlis" sortable style={{minWidth: '8rem'}} body={capitalizedBody}></Column>
                    <Column field="dateTime" header="Datum" sortable style={{minWidth: '8rem'}} body={dateTemplate}></Column>
                    <Column field="location" filter filterMatchMode="contains" header="Ort" sortable style={{minWidth: '8rem'}}></Column>
                    <Column field="expenseDetails.estimateParticipants" sortable header="Erw. Teilnehmer"></Column>
                    <Column field="status" filter filterMatchMode="contains" header="Status" sortable style={{minWidth: '8rem'}} body={statusBody}></Column>
                    <Column field="costs" header="Gesamtkosten" sortable style={{minWidth: '8rem'}} body={totalCosts} ></Column>
                    <Column field="expenseDetails.approvedAmount" header="Kosten genehmigt" sortable style={{minWidth: '8rem'}}></Column>
                </DataTable>
            </div>
            <ConfirmDialog />
            <Dialog visible={eventDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                    header="Event Details" modal className="p-fluid" onHide={hideDialog}>
                <CreateUpdateEvent event={event} onSubmit={saveEvent}/>
                <FullOverlaySpinner show={createEventLoading || updateEventLoading }/>
            </Dialog>

            <Dialog visible={approveEventDialog} style={{width: '60rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}}
                    header="Approve Events" modal className="p-fluid" onHide={hideApproveDialog}>
                <PrintPanel>
                    {event ? <ApproveEvent event={event} mode={"approve"} onApprove={loadItems}/> : selectedEvents?.map(sEvent => <ApproveEvent event={sEvent} onApprove={loadItems} mode={"approve"}/>)}
                </PrintPanel>
            </Dialog>

            <Dialog visible={viewEventDialog} style={{width: '60rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}}
                    header="View Events" modal className="p-fluid" onHide={hideViewDialog}>
                <PrintPanel>
                    {event ? <ApproveEvent event={event} mode={"view"}/> : selectedEvents?.map(sEvent => <ApproveEvent event={sEvent} mode={"view"}/>)}
                </PrintPanel>
            </Dialog>

            <Dialog visible={rejectEventDialog} style={{width: '32rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}}
                    header="Event Details" modal className="p-fluid" onHide={hideRejectDialog}>
                {event ? <CancelRejectEvent event={event} onApprove={loadItems} status="REJECTED" onDone={hideRejectDialog}/> : selectedEvents?.map(sEvent => <CancelRejectEvent event={sEvent} onApprove={loadItems} status="REJECTED" onDone={hideRejectDialog}/>)}
            </Dialog>

            <Dialog visible={cancelEventDialog} style={{width: '32rem'}} breakpoints={{'960px': '75vw', '641px': '90vw'}}
                    header="Event Details" modal className="p-fluid" onHide={hideCancelDialog}>
                {event ? <CancelRejectEvent event={event} onApprove={loadItems} status="EVENT_CANCELED" onDone={hideCancelDialog}/> : selectedEvents?.map(sEvent => <CancelRejectEvent event={sEvent} onApprove={loadItems} status="EVENT_CANCELED" onDone={hideCancelDialog}/>)}
            </Dialog>
            
            <Dialog visible={deleteEventDialog} style={{width: '32rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}} header="Confirm" modal
                    footer={deleteEventDialogFooter} onHide={hideDeleteEventDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {event && (
                        <span>
                            Are you sure you want to delete <b>{event.name}</b>?
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteEventsDialog} style={{width: '32rem'}}
                    breakpoints={{'960px': '75vw', '641px': '90vw'}} header="Confirm" modal
                    footer={deleteEventsDialogFooter} onHide={hideDeleteEventsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {event && <span>Are you sure you want to delete the selected events?</span>}
                </div>
            </Dialog>
        </div>
    );
}