import React, {useState} from "react";
import {Panel} from "primereact/panel";
import moment from "moment";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {addComment, updateEventStatus} from "../../store/events/events.slice";
import {useDispatch} from "react-redux";
import {defaultErrorMessageToast, getToast} from "../../utils/toastHelper";
import {InputTextarea} from "primereact/inputtextarea";
import {Divider} from "primereact/divider";


export default function ApproveEvent(props) {
    const dispatch = useDispatch();
    const {mode} = props;
    const [event, setEvent] = useState(props.event);
    const [amountValue, setAmountValue] = useState();
    const [newComment, setNewComment] = useState();
    const toast = getToast();
    const toastErrorMessage = (response) => {defaultErrorMessageToast(toast,response)};

    const eventUpdated = (response) => {
        props.onApprove ? props.onApprove() : void(0);
        setEvent(response);
    };

    const commentAdded = (response) => {
        setNewComment("");
        setEvent(response);
    };

    const updateStatusOfEvent  = () => {
        console.log(newComment)
        console.log(amountValue)
        dispatch(updateEventStatus({finalData: {eventId: event.id, status: "EVENT_APPROVED", additionalData: {approvedAmount: parseFloat(amountValue).toFixed(2), comment: newComment}}, callbackSuccess: eventUpdated, callbackError: toastErrorMessage}))
    }

    const addCommentText  = () => {
        dispatch(addComment({finalData: {eventId: event.id, additionalData: {comment: newComment}}, callbackSuccess: commentAdded, callbackError: toastErrorMessage}))
    }

    const headerTemplate = (options) => {
        const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
        const className = `${options.className} justify-content-start`;
        const titleClassName = `${options.titleClassName} ml-2`;
        const style = { fontSize: '1rem' };

        return (
            <div style={{display: "flex", justifyContent: "space-between", border: "1px solid #dee2e6"}}>
                <div className={className}>
                    <span className={titleClassName}
                          style={style}>TYP: {event.eventType.tanzeem.charAt(0).toUpperCase()}</span>
                </div>
                <div className={className} style={{backgroundColor:"white", border: "none", textAlign: "center"}}>
                    <span className={titleClassName} style={style}>
                       {event.eventType.label}: {event.tanzeemUnit.toUpperCase()}<br/>
                       <span style={{
                           fontWeight: "normal",
                           color: "#b6b6b6"
                       }}>{moment(event.startDateTime).format("DD.MM. HH:mm")} - {moment(event.endDateTime).format("DD.MM. HH:mm")}</span>
                    </span>
                </div>
                <div className={className}>
                    <span className={titleClassName}
                          style={style}>{event.eventType.tanzeemLevel.charAt(0).toUpperCase()}</span>
                </div>
            </div>
        );
    };

    return <Panel headerTemplate={headerTemplate}>
        <div className="grid">

            <div className="col-4" style={{color: "#b6b6b6"}}>
                <div className="text-center p-2 border-round-sm">
                    Antragsteller:<br/> {event.requesterName + " (" + event.requesterAimsId + ")"}
                </div>
            </div>
            <div className="col-4">
                <div className="text-center p-2 border-round-sm font-bold">Erwartete
                    Teilnehmer: {event.expenseDetails?.estimateParticipants}</div>
            </div>
            <div className="col-4" style={{color: "#b6b6b6"}}>
                <div>Ort: {event.location}</div>
                <div>Datum: {moment(event.startDateTime).format("DD.MM. HH:mm")} - {moment(event.endDateTime).format("DD.MM. HH:mm")}</div>
            </div>
        </div>
        <div className="grid nested-grid">
            <div className="col-6" style={{marginRight: "12px"}}>
                <div className="grid nested-grid">
                    <div className="relative col-12 border-1 border-black-alpha-20 border-round"
                         style={{marginBottom: "12px"}}>
                        <div
                            className="absolute top-0 left-0 justify-content-center bg-black-alpha-10 border-black-alpha-20 border-1">BUDGET
                        </div>
                        <div className="text-center p-2 border-round-sm font-bold">Kostenanfrage: {event.expenseDetails?.hospitality + event.expenseDetails?.rent + event.expenseDetails?.others - event.expenseDetails?.selfInvolvement}€</div>
                        <div className="grid nested-grid" style={{paddingTop: "20px"}}>
                            <div className="col-12">
                                <div className="grid nested-grid">
                                    <div className="col-3">
                                        <div className="">Kosten:</div>
                                    </div>
                                    <div className="col-3" style={{textAlign: "end"}}>
                                        <div
                                            className="">{event.expenseDetails?.hospitality + event.expenseDetails?.rent + event.expenseDetails?.others}€
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="">SB: {event.expenseDetails?.selfInvolvement}€</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className="grid nested-grid">
                                    <div className="col-3">
                                        <div className="">Hospitality:</div>
                                    </div>
                                    <div className="col-3" style={{textAlign: "end"}}>
                                        <div className="">{event.expenseDetails?.hospitality}€</div>
                                    </div>
                                    <div className="col-6">
                                        <div
                                            className="">/{parseFloat(event.expenseDetails?.hospitality / event.expenseDetails?.estimateParticipants).toFixed(2)}€
                                            p.P.
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div>Rent:</div>
                                    </div>
                                    <div className="col-3" style={{textAlign: "end"}}>
                                        <div>{event.expenseDetails?.rent}€</div>
                                    </div>
                                    <div className="col-6">
                                    </div>
                                    <div className="col-3">
                                        <div>Sonstige:</div>
                                    </div>
                                    <div className="col-3" style={{textAlign: "end"}}>
                                        <div>{event.expenseDetails?.others}€</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="relative col-12 border-1 border-black-alpha-20 border-round">
                        <div
                            className="absolute top-0 left-0 justify-content-center bg-black-alpha-10 border-black-alpha-20 border-1">MENUS
                        </div>
                        <div className="text-center p-2 border-round-sm font-bold">Anzahl
                            Speisen: {event.expenseDetails?.numberOfMenus}</div>
                        <p>{event.expenseDetails?.menus?.split('\n').map(item =>
                            <React.Fragment>{item}<br/></React.Fragment>)}</p>
                    </div>
                </div>
            </div>
            <div className="col-5">
                <div className="grid nested-grid">
                    <div className="relative col-12 border-1 border-black-alpha-20 border-round"
                         style={{marginBottom: "12px"}}>
                        <div
                            className="absolute top-0 left-0 justify-content-center bg-black-alpha-10 border-black-alpha-20 border-1">AGENDA
                        </div>
                        <div
                            className="absolute top-0 right-0 justify-content-center bg-black-alpha-10 border-black-alpha-20 border-1">Ort: {event.location}
                        </div>
                        <p>{event.agenda?.split('\n')?.map(item => <React.Fragment>{item}<br/></React.Fragment>)}</p>
                    </div>
                    <div className="relative col-12 border-1 border-black-alpha-20 border-round"
                         style={{paddingTop: "22px"}}>
                        <div
                            className="absolute top-0 left-0 justify-content-center bg-black-alpha-10 border-black-alpha-20 border-1">KOMENTARE
                        </div>

                        {event.comments.map(comment => <div>
                            <div className="comment">{comment.commentText}</div>
                            <div style={{fontSize: "10px", color: "#7a7a7a", justifyContent: "end", display: "grid"}}>
                                <div className="commenter">{comment.commenterName} ({comment.commenterId})</div>
                                <div className="commentDate">{moment(comment.commentDate).format("DD.MM. HH:mm")}</div>
                            </div>
                            <Divider/>
                        </div>)}

                        <div>
                            <InputTextarea className="w-100"
                                           rows={1}
                                           autoResize={true}
                                           value={newComment}
                                           placeholder="Neuer Kommentar"
                                           onChange={(e) => setNewComment(e.target.value)}/>
                        </div>

                        {event.status === "IN_PROGRESS" && mode === "approve" ? "" :
                            <div className="w-100 flex justify-content-end"><Button onClick={addCommentText}
                                                                                    icon="pi pi-plus" rounded outlined
                                                                                    severity="secondary"
                                                                                    aria-label="Search"/></div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="grid p-2">
            <div className="col-3">
            </div>
                <div className="col-6 bg-black-alpha-10 border-black-alpha-10 border-1 border-round-2xl">
                    <div className="grid nested-grid">
                        <div className="col-12"></div>
                        <div className="col-12">
                            <div>Vorschlag Abt. Maal:</div>
                            <div className="text-center font-light font-italic">coming soon!</div>
                        </div>
                        <div className="col-12">
                            <div className="font-bold">Freigabe Sadr Majlis:</div>
                            {event.status === "IN_PROGRESS" && mode === "approve" ? <div>
                                            <span className="p-inputgroup customNumbers">
                                                <InputNumber className="w-5"
                                                             value={amountValue}
                                                             placeholder={event.expenseDetails?.hospitality + event.expenseDetails?.rent + event.expenseDetails?.others - event.expenseDetails?.selfInvolvement}
                                                             onValueChange={(e) => setAmountValue(e.target.value)}
                                                             mode="currency" currency="EUR" locale="de-DE"/>
                                            </span>
                                <Button label="Freigeben" className="hideOnPrint" severity="success" icon="pi pi-check"
                                        onClick={updateStatusOfEvent}/>
                            </div> : <><br/><div className="text-center font-bold"
                                          style={{color: "#67a56f"}}>{event.expenseDetails?.approvedAmount > 0 ? event.expenseDetails?.approvedAmount + "€" : "___________ €"}</div></>}
                        </div>
                    </div>
                </div>
            </div>
    </Panel>
}